<template>
  <MainContainer>
    <template #leftPanelTop>
      <!-- TOP CIRCLE NAVIGATION -->
      <div style="width:100%;height:60px;flex-grow: 0" class="mt-3">
        <div class="d-flex justify-content-center border-center " style="justify-content: space-between !important;position: relative;">
          <CircleNavigation
            v-for="(page, i) in pageItems"
            :key="i"
            :selected-page="selectedPage"
            :action-is-conditional-on="page.actionIsConditionalOn"
            :page="page"
            @selectPage="selectPage($event)"
          />
        </div>
        <!--timeline line-->
        <div class="mx-auto mt-4 navigationTimelineLine" />
      </div>

      <!--choose hero-->
      <h2 class="primary-colour font-weight-bold text-center mt-2 mt-lg-3 mobileAdjustment" style="height: 30px !important;font-size: clamp(1.1rem, 2vw, 2.5rem)">
        <AnimatedWord v-if="hero != 0 || currentBasket.length === 0" :word="sectionTitle" />
        <span v-else>{{ sectionTitle }}</span>
      </h2>

      <Hero
        v-if="selectedPage === 1"
        style="background-color: var(--bg-color);"
      />

      <!--choose colour-->
      <Colour
        v-else-if="selectedPage === 2"
        style="background-color: var(--bg-color)"
      />

      <!--choose sport-->
      <Sport
        v-else-if="selectedPage === 3"
        style="background-color: var(--bg-color)"
      />

      <!--choose number-->
      <Number
        v-else-if="selectedPage === 4"
        style="background-color: var(--bg-color)"
      />

      <!--choose initials-->
      <Initials
        v-else-if="selectedPage === 5"
        style="background-color: var(--bg-color)"
      />

      <!-- MOBILE HERO PREVIEW -->
      <mdb-btn
        :class="{ 'animatingPreview' : animatePreview }"
        class="d-md-none mobileButtonPreviewPlay"
        @click="showPreview"
      >
        PREVIEW
      </mdb-btn>
    </template>

    <template #leftPanelButtons>
      <div class="panel-button-grid">
        <mdb-btn
          v-show="selectedPage > 1 && !loading"
          class="btn btn-out-primary btn-radius btn-sm btn-md mr-auto"
          :disabled="selectedPage === 1"
          style="font-size:0.8rem;width:110px;"
          @click="selectPage(selectedPage - 1)"
        >
          BACK
        </mdb-btn>
      </div>
      <div class="panel-button-grid">
        <RandomiseHeroButton
          class="mx-auto"
          @randomise="selectPage(5)" 
        />
      </div>
      <div class="panel-button-grid">
        <mdb-btn
          v-show="selectedPage < 5 && !addedToBasket"
          class="btn primary-btn btn-radius btn-sm btn-md ml-auto"
          :disabled="!allowProgression"
          style="font-size:0.8rem;width:110px;"
          @click="selectPage(selectedPage + 1)"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-show="selectedPage >= 5 && !addedToBasket"
          class="btn primary-btn btn-radius btn-sm btn-md ml-auto"
          :disabled="!allowProgression"
          style="font-size:0.8rem;"
          @click="addToBasket()"
        >
          <span class="d-none d-md-inline ">
            ADD TO BASKET
          </span>
          <span class="d-md-none">
            <i class="fa-plus-circle fa" />
            BASKET
          </span>
        </mdb-btn>
      </div>
    </template>

    <template #rightPanel>
      <router-view />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import Hero from '@/components/playSections/hero.vue'
import Sport from '@/components/playSections/sport.vue'
import Colour from '@/components/playSections/colour.vue'
import Number from '@/components/playSections/number.vue'
import Initials from '@/components/playSections/initials.vue'
import RandomiseHeroButton from '@/components/playSections/randomiseHeroButton.vue'
import baseService from '@/api-services/base.service'
import basketService from '@/api-services/basket.service'
import CircleNavigation from '@/components/circleNavigation.vue'
import MainContainer from '@/components/layout/mainContainer.vue'
import AnimatedWord from "@/components/UI/bouncingWordWrapper.vue"

export default {
  name: 'Play',
  components: {
    Hero,
    Sport,
    Colour,
    Number,
    Initials,
    CircleNavigation,
    MainContainer,
    AnimatedWord,
    RandomiseHeroButton
  },
  data () {
    return {
      addedToBasket: false,
      prize: 0,
      loading: false,
      animatePreview: false
    }
  },
  computed: {
    selectedPage () {
      try {
        return + this.$route.query.page
      } catch { return 0 }
    },
    pageItems () {
      return [
        { index: 1, isCurrent: this.heroSelected, text: 'Hero', actionIsConditionalOn: true },
        { index: 2, isCurrent: this.colourSelected, text: 'Colour', actionIsConditionalOn: this.hero !== 0 },
        { index: 3, isCurrent: this.sportSelected, text: 'Sport', actionIsConditionalOn: this.colour !== '#e1e1e1' },
        { index: 4, isCurrent: this.numberSelected, text: 'Number', actionIsConditionalOn: this.sport !== 0 },
        { index: 5, isCurrent: this.firstInitialSelected && this.secondInitialSelected, text: 'Initials', actionIsConditionalOn: this.number !== 0 }
      ]
    },
    sectionTitle () {
      const title = this.selectedPage === 1 ? 'CHOOSE YOUR HERO'
        : this.selectedPage === 2 ? 'CHOOSE YOUR HERO\'S COLOUR'
          : this.selectedPage === 3 ? 'CHOOSE YOUR HERO\'S SPORT'
            : this.selectedPage === 4 ? 'CHOOSE YOUR HERO\'S NUMBER'
              : this.selectedPage === 5 ? 'CHOOSE YOUR HERO\'S INITIALS' : ''
      return title
    },
    ...mapGetters([
      'hero',
      'sport',
      'colour',
      'number',
      'firstInitial',
      'secondInitial',
      'userData',
      'currentBasket',
      'mobileView'
    ]),
    heroSelected () {
      return this.hero !== 0
    },
    sportSelected () {
      return this.sport !== 0
    },
    numberSelected () {
      return this.number !== 0
    },
    colourSelected () {
      return this.colour !== '#e1e1e1'
    },
    firstInitialSelected () {
      return this.firstInitial !== ''
    },
    secondInitialSelected () {
      return this.secondInitial !== ''
    },
    allowProgression () {
      if (this.selectedPage === 0) { return true }
      if (this.selectedPage === 1 && this.heroSelected) { return true }
      if (this.selectedPage === 2 && this.colourSelected) { return true }
      if (this.selectedPage === 3 && this.sportSelected) { return true }
      if (this.selectedPage === 4 && this.numberSelected) { return true }
      if (this.selectedPage === 5 && this.firstInitialSelected && this.secondInitialSelected) { return true }
      return false
    }
  },
  watch: {
    hero () {
      this.setPreviewButtonAnimation()
    },
    colour () {
      this.setPreviewButtonAnimation()
    },
    sport () {
      this.setPreviewButtonAnimation()
    },
    number () {
      this.setPreviewButtonAnimation()
    },
    secondInitial () {
      this.setPreviewButtonAnimation()
    }
  },
  created () {
    this.$store.commit('setMobilePreviewShowing', false)
    if (this.$route.path == '/play') {
      this.$store.commit('storePanelViewedOnMobile', 'Left')
      this.$router.push({path: '/play', query: { page: 1 }}).catch(() => {})
    } else {
      this.$store.commit('storePanelViewedOnMobile', 'Right')
    }
    this.$store.commit('storeId', 0)
    this.resetBasket()
    this.publicPrizePot()
  },
  methods: {
    setPreviewButtonAnimation () {
      this.animatePreview = true
      setTimeout(() => {
        this.animatePreview = false
      }, 600)
    },
    resetBasket () {
      this.addedToBasket = false
      //this.$store.commit('storeHero', 0)
      //this.$store.commit('storeSport', 0)
      //this.$store.commit('storeColour', '#e1e1e1')
      //this.$store.commit('storeNumber', 0)
      //this.$store.commit('storeFirstInitial', '')
      //this.$store.commit('storeSecondInitial', '')
    },
    goToPlay () {
      this.$router.push({path: '/play', query: { page: 1 }}).catch(() => {})
    },
    publicPrizePot () {
      baseService.publicPrizePot(this.userData.access_token).then((res) => {
        this.prize = res.data
      })
    },
    showPreview () {
      this.$store.commit('setMobilePreviewShowing', true)
    },
    async addToBasket () {
      const newCard = {
        id: 0,
        Hero: this.hero,
        Sport: this.sport,
        Colour: this.colour,
        Number: this.number,
        FirstInitial: this.firstInitial,
        SecondInitial: this.secondInitial,
        ClubId: 0
      }
      this.addedToBasket = true
      if (this.userData.aut) {
        this.loading = true
        const basketId = await basketService.addToBasket(newCard, this.userData.access_token)
        this.$store.commit('addCardToBasket', { ...newCard, BasketOrdersId: basketId.data })
        this.resetBasket()
        if (this.mobileView) {
          this.$router.push('/basket/checkout').catch(() => {})
          this.$store.commit('storePanelViewedOnMobile', 'Left')
        } else {
          this.selectPage(1)
        }
      } else {
        // any card without a "BasketOrdersId" key will be added later to the basket backend once a player signs in
        this.resetBasket()
        this.$store.commit('addCardToBasket', newCard)
        if (this.mobileView) {
          if (this.userData.aut) {
            this.$router.push('/basket/checkout').catch(() => {})
          } else {
            this.$router.push('/basket/auth').catch(() => {})
          }
          this.$store.commit('storePanelViewedOnMobile', 'Left')
        } else {
          this.$store.commit('storePanelViewedOnMobile', 'Right')
          this.$router.push({path: '/play', query: { page: 1 }}).catch(() => {})
        }
      }
      this.loading = false
    },
    addAnotherCard () {
      this.addedToBasket = false
      this.resetBasket()
      this.selectPage(1)
    },
    selectPage (step) {
      if (this.selectedPage === 5) { this.addedToBasket = false }
      this.$router.push({path: '/play', query: { page: step }}).catch(() => {})
    }
  }
}
</script>

<style>
.panel-button-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
}
.mobileButtonPreviewPlay {
	border-radius: 50px;
  position: absolute;
  bottom: -23px;
  left: calc(50% - 80px);
  width: 150px;
  font-size: 1rem;
  background: #fff !important;
  color: var(--bg-color) !important;
  border: var(--bg-color) 5px solid;
  padding-top: 3px;
}
.animatingPreview {
	animation: previewButtonAnimation 1s forwards 1;
}
@keyframes previewButtonAnimation {
	60% {
		transform: scale(0.8);
	}
	80% {
		transform: scale(1.2);
	}
	100% {
    transform: scale(1);
	}
}

@media (max-width: 576px) {
  .mobileMarginTop {
    margin-top:40px !important;
  }
  .formMarginTop {
    margin-top: -25px;
  }
  .scrollContainer {
    float:left;
    height: calc(100vh - 295px);
    width:100%;
    padding:3px;
  }
  .scrollContent {
    height: calc(100vh - 315px);
    overflow:auto;
    background:#fff;
  }
  .mobileAdjustment {
    transform: translateY(10px);
  }
}
.resp-bot {
  bottom: 12px;
}
@media (min-width: 768px) {
  .resp-bot {
    font-size: 20px;
  }
}
.scrollContainer {
  float:left;
  height: calc(100vh - 380px);
  width:100%;
  padding:3px;
}
.scrollContent {
  height: calc(100vh - 430px);
  overflow:auto;
  background:#fff;
}
.formMarginTop {
  margin-top: -15px;
}
*::-webkit-scrollbar {
  display:block;
  width: 4px;
  background-color: #e1e1e1;
 }
*::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
}
</style>
