<template>
  <div
    class="d-flex flex-column"
    style="height:100%;margin-top:15px;"
    :class="{'margMobBottom': safari === false }"
  >
    <!-- grid -->
    <div class="grid-8 mb-3" style="width:100%;color:#fff;">
      <div
        v-for="option in options"
        :key="option.value"
        style="border: 1px solid #fff;height:100%; position:relative;"
        :style="{'background' : `linear-gradient(to bottom, ${lightenDarkenColor(option.hex, -30)}, ${lightenDarkenColor(option.hex, 30)}, ${lightenDarkenColor(option.hex, 200)}`, 'color': `${lightenDarkenColor(option.hex, -80)}`}"
        class="gridItem d-flex align-items-end font-weight-bold"
        @click="selectColour(option)"
      >
        <CheckedCircle
          v-if="option.hex === colour || (colour === '#ffffff' && option.hex === '#999999')"
          style="top: 15%"
        />
        <div
          style="font-size:clamp(1.1rem, 2.5vw, 2rem);writing-mode: vertical-rl;text-orientation: mixed;transform:rotate(180deg);width:100%;"
          class="py-2 py-md-3 d-flex align-items-center"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckedCircle from '@/components/checkedCircle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Colour',
  components: {
    CheckedCircle
  },
  data () {
    return {
      options: [
        { text: 'RED', hex: '#ff0000', value: 1 },
        { text: 'BLUE', hex: '#0000ff', value: 2 },
        { text: 'YELLOW', hex: '#ffff00', value: 3 },
        { text: 'GREEN', hex: '#008000', value: 4 },
        { text: 'ORANGE', hex: '#ff9900', value: 5 },
        { text: 'PURPLE', hex: '#800080', value: 6 },
        { text: 'PINK', hex: '#fa42d2', value: 7 },
        { text: 'WHITE', hex: '#999999', value: 8 }
      ]
    }
  },
  computed: {
    ...mapGetters(['colour', 'safari', 'randomising'])
  },
  watch: {
    randomising () {
      if (this.randomising == false) {
        const colour = Math.floor(Math.random() * 8) + 1
        const option = this.options.find(v => v.value == colour)
        this.selectColour(option)
      }
    }
  },
  methods: {
    selectColour ({ hex }) {
      if (hex === '#999999') hex = '#ffffff'
      this.$store.commit('storeColour', hex)
    },
    lightenDarkenColor (color, amount) {
      return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
    }
  }
}
</script>

<style scoped>
@media (max-width: 576px) {
  .margMobBottom {
    margin-bottom: 20px;
  }
}
</style>
