<template>
  <div
    class="d-flex flex-column"
    style="height:100%;margin-top:15px;"
    :class="{'margMobBottom': safari === false }"
  >
    <!-- grid -->
    <div
      class="grid-8 mb-3"
      style="width:100%;"
      :style="{'color': colour}"
    >
      <div
        v-for="option in options"
        :key="option"
        style="height:100%; font-size:clamp(3rem, 5vw, 5rem)"
        class="gridItem d-flex align-items-center justify-content-center font-weight-bold initialOption"
        :class="number == option ? 'selectedOption' : 'nonSelectedOption'"
        @click="selectNumber(option)"
      >
        <span style="transform: scaleY(1.2)">{{ option }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Hero',
  data () {
    return {
      options: [1, 2, 3, 4, 5, 6, 7, 8]
    }
  },
  computed: {
    ...mapGetters(['colour', 'number', 'safari'])
  },
  methods: {
    selectNumber (option) {
      this.$store.commit('storeNumber', option)
    }
  }
}
</script>

<style scoped>
@media (max-width: 576px) {
  .margMobBottom {
    margin-bottom: 20px;
  }
}
</style>
