<template>
  <div
    class="d-flex justify-content-center align-items-center checkedCircle m-0 p-1"
  >
    <i
      style="cursor:pointer;color:var(--bg-color);font-size:clamp(1.3rem, 1.5vw, 1.8rem)"
      class="fas fa-check mt-1"
    />
  </div>
</template>

<script>

export default {
  name: 'CheckedCircle'
}
</script>

<style>
  .checkedCircle {
    position:absolute;
    top:45%;
    z-index: 1;
    left: calc(50% - 22px);
    height: 45px;
    width: 45px;
    background: var(--pr-color);
    border-radius: 50%;
  }
  @media (max-width: 992px) {
    .checkedCircle {
      left: calc(50% - 15px);
      height: 30px;
      width: 30px;
    }
  }

.checkedCircle:hover, .checkedCircle {
  animation: grow-shrink 1s forwards;
}

@keyframes grow-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
