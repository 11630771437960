<template>
  <div
    ref="initialsParentContainer"
    class="d-flex flex-column"
    style="height: 100%; overflow:auto;"
  >
    <div
      class="initialFontSize primary-colour flex-wrap d-flex align-items-center py-2 py-md-3 mb-4 my-xl-auto desktopMarginTop"
    >
      <div class="initialsGridContainer col-12 col-xl-6 m-0 p-0 pr-xl-4 mb-4 mb-xl-0">
        <span class="initialTitle initialsGridItem" style="color: var(--lt-color)">FIRST</span>
        <!-- FIRST INITIAL -->
        <div
          v-for="option in options"
          :key="option"
          class="cp initialsGridItem"
          @click="selectFirstInitial(option)"
        >
          <div
            :class="firstInitial == option ? 'selectedOption' : 'nonSelectedOption'"
            class="initialOption m-1"
          >
            {{ option }}
          </div>
        </div>
      </div>
      <div class="initialsGridContainer col-12 col-xl-6 m-0 p-0 pl-xl-4">
        <span class="initialTitle initialsGridItem" style="color: var(--lt-color)">SECOND</span>
        <!-- SECOND INITIAL -->
        <div
          v-for="option in options"
          :key="option"
          class="cp initialsGridItem"
          @click="selectSecondInitial(option)"
        >
          <div
            :class="secondInitial == option ? 'selectedOption' : 'nonSelectedOption'"
            class="initialOption m-1"
          >
            {{ option }}
          </div>
        </div>
        <span ref="toScrollTo" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Initials',
  data () {
    return {
      options: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      windowHeight: window.innerHeight
    }
  },
  computed: {
    ...mapGetters(['firstInitial', 'secondInitial'])
  },
  methods: {
    selectFirstInitial (option) {
      if (this.windowHeight < 760) {
        let scrollElement = this.$refs.toScrollTo
        scrollElement.scrollIntoView({ behavior: "smooth" })
      }
      this.$store.commit('storeFirstInitial', option)
    },
    selectSecondInitial (option) {
      this.$store.commit('storeSecondInitial', option)
    }
  }
}
</script>

<style scoped>
.initialsGridContainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}
.initialsGridItem {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.initialOption {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.desktopMarginTop {
  margin-top: 30px !important;
}
@media (min-width: 578px) {
  .initialOption {
    width: 45px;
    height: 45px;
  }
  .initialFontSize {
  font-size: clamp(1.2rem, 2vw, 2.5rem);
  }
}
.initialTitle {
  grid-column: 1 / span 2;
}
.initialsSize {
  font-size: 2rem !important;
}
.initialFontSize {
  font-size: clamp(1.2rem, 2vw, 2rem);
}
.initialTitle {
  font-size: clamp(0.9rem, 2vw, 1.5rem);
}

</style>
