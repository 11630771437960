<template>
  <div
    class="d-flex flex-column"
    style="height:100%;margin-top:15px;"
    :class="{'margMobBottom': safari === false }"
  >
    <!-- grid -->
    <div class="grid-9 mb-3" style="width:100%;height:100%;color:black;">
      <div
        v-for="(sportObject, i) in sportsList"
        :key="i"
        style="overflow: hidden; text-align: center !important;"
        class="gridItem d-flex align-items-end font-weight-bold sportContainer p-0"
        @click="selectSport(sportObject.sportNumber)"
      >
        <img :src="require(`@/assets/sports/${sportObject.imageName}`)" class="sportImage">
        <div class="py-1 py-md-2 py-lg-3 d-flex align-items-center sportText px-1">
          <div style="width:-webkit-fill-available;">
            {{ sportObject.text }}
          </div>
        </div>
        <CheckedCircle v-if="sport === sportObject.sportNumber" style="top: 8%;" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CheckedCircle from '@/components/checkedCircle.vue'

export default {
  name: 'Sport',
  components: {
    CheckedCircle
  },
  data () {
    return {
      options: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      sportsList: [
        { sportNumber: 1, imageName: 'football.back.png', text: 'FOOTBALL' },
        { sportNumber: 2, imageName: 'rugby.back.png', text: 'RUGBY' },
        { sportNumber: 3, imageName: 'cricket.back.png', text: 'CRICKET' },
        { sportNumber: 4, imageName: 'golf.back.png', text: 'GOLF' },
        { sportNumber: 5, imageName: 'basketball.back.png', text: 'BASKETBALL' },
        { sportNumber: 6, imageName: 'netball.back.png', text: 'NETBALL' },
        { sportNumber: 7, imageName: 'americanfootball.back.png', text: 'AMERICAN FOOTBALL' },
        { sportNumber: 8, imageName: 'tennis.back.png', text: 'TENNIS' },
        { sportNumber: 9, imageName: 'boxing.back.png', text: 'BOXING' }
      ]
    }
  },
  computed: {
    ...mapGetters(['sport', 'safari'])
  },
  methods: {
    selectSport (option) {
      this.$store.commit('storeSport', option)
    }
  }
}
</script>

<style scoped>
.sportContainer {
  border: 1px solid #000;
  height:100%;
  position: relative;
  color: var(--pr-color);
}
.sportContainer:hover {
  color: var(--lt-color);
  transition: 0.2s;
}
.sportContainer:hover img {
  opacity: 0.5;
}

.sportText {
  white-space: pre-wrap;
  font-size: clamp(0.65rem, 1.5vw, 1.6rem);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform:rotate(180deg);
  width: fit-content;
  margin: 0 auto;
}

.sportImage {
  object-fit: cover;
  object-position: top;
  height: 60%;
  width:100%;
  transform: translateY(-70%);
  position: absolute;
}
@media (max-width: 768px) {
  .sportImage {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width:100%;
    transform: translateY(0);
    position: absolute;
  }
  .sportText {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    padding-left: 10px;
    text-align: center;
    background: var(--bg-color);
    width: 100%;
    white-space: normal;
  }
}
@media (max-width: 576px) {
  .margMobBottom {
    margin-bottom: 20px;
  }
}
</style>
