<template>
  <div class="mx-2 mx-lg-3" style="text-align: -webkit-center;">
    <a
      :class="{ 'btn-blank-primary': selectedPage === page.index, 'btn-blank-outline': selectedPage != page.index }"
      type="button"
      class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent m-0 mb-1"
      @click="selectPage(page.index)"
    >
      <i
        v-if="page.isCurrent || selectedPage > page.index"
        style="cursor:pointer;color:var(--bg-color)"
        class="fas fa-check iconEntry loaded"
      />
    </a>
    <span
      class="d-none d-sm-block"
      :class="selectedPage == page.index ? 'primary-colour': 'text-white'"
      style="font-size:12px;;font-weight:bold;"
    >{{ page.text }}</span>
    <span :class="selectedPage == page.index ? 'primary-colour': 'text-white'" class="d-sm-none">{{ page.index }}</span>
  </div>
</template>

<script>

export default {
  name: 'CircleNavigation',
  props: {
    page: { 
      type: [Object],
      default: function () {
        return {}
      }
    },
    selectedPage: { 
      type: [Number],
      default: function () {
        return 0
      }
    },
    actionIsConditionalOn: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    selectPage (page) {
      if (this.actionIsConditionalOn) {
        this.$emit('selectPage', page)
      }
    }
  }
}
</script>

<style scoped>
.iconEntry {
  font-size: 2rem;
  animation: pop-in 0.8s ease-in-out;
  opacity: 0; 
  transform: scale(0.5);
  transition: all 0.3s ease-in-out;
  opacity: 1;
  transform: scale(1);
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
